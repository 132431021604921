import * as React from "react";
import { Stack } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import DownloadIcon from "@mui/icons-material/Download";
import { Exam } from "../../../domain/Season";
import { UUID } from "../../../domain/UUID";
import Centre from "../../../domain/Centre";
import StudentExamResult from "../../../domain/StudentExamResult";
import { downloadCentreResults } from "../../../gateway/databaseViews";
import Student from "../../../domain/Student";
import ExamLevel from "../../../domain/ExamLevel";
import Season from "../../../domain/Season";

interface Props {
    readonly centreId: UUID<Centre>;
    readonly exams: Exam[];
    readonly results: { [key: UUID<Student>]: StudentExamResult };
    readonly season: Season;
}

export default function CentreResultsDownloadButtons({ centreId, exams, results, season }: Props) {
    const [loadingExams, setLoadingExams] = React.useState<{ [key: UUID<Exam>]: boolean }>({});

    // Get unique exam IDs from results
    const examIdsWithResults = new Set(
        Object.values(results)
            .map(result => result.examId)
    );

    // Filter exams to only those that have results and sort by level
    const availableExams = exams
        .filter(exam => examIdsWithResults.has(exam.examId))
        .sort((a, b) => ExamLevel.comparator()(a.level, b.level));

    if (availableExams.length === 0) {
        return null;
    }

    const handleDownload = async (exam: Exam) => {
        setLoadingExams(prev => ({ ...prev, [exam.examId]: true }));
        try {
            const examName = Exam.name(season, exam).toLowerCase().replace(/\s+/g, '-');
            await downloadCentreResults(
                centreId,
                exam.examId,
                examName
            );
        } finally {
            setLoadingExams(prev => ({ ...prev, [exam.examId]: false }));
        }
    };

    return (
        <Stack 
            direction={{ xs: 'column', md: 'row' }}
            spacing={1} 
        >
            {availableExams.map(exam => (
                <LoadingButton
                    key={exam.examId}
                    variant="contained"
                    loading={loadingExams[exam.examId] ?? false}
                    onClick={() => handleDownload(exam)}
                    startIcon={<DownloadIcon />}
                >
                  Full {ExamLevel.pretty(exam.level)} Results
                </LoadingButton>
            ))}
        </Stack>
    );
} 
