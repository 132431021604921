import * as React from "react";
import { useEffect, useState } from "react";
import { getMyAssignments } from "../../gateway/marking";
import { Box, Card, CardContent, CardHeader, Grid, Stack, Typography } from "@mui/material";
import Season, { Exam } from "../../domain/Season";
import { useAccount } from "../account/AccountContext";
import { MarkingAssignment } from "../../domain/MarkingAssignment";
import AssignedCentre from "./AssignedCentre";
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import SeasonState from "../../domain/SeasonState";
import Account, { AccountRole } from "../../domain/Account";

interface Props {
  readonly season: Season,
  readonly exam: Exam
}

export default function MarkingAssignmentsCard({ season, exam }: Props) {
  const { account } = useAccount();
  const [assignments, setAssignments] = useState<MarkingAssignment[]>([]);

  useEffect(() => {
    getMyAssignments(exam.examId).then(a => setAssignments(a));
  }, [exam.examId, account?.id]);

  if (account == undefined) {
    return null;
  }

  if (assignments.length == 0) {
    return (
      <Card variant="outlined" sx={{ borderColor: '#d0d0d0' }}>
        <CardHeader
          title="You haven't been assigned any Advanced scripts to mark"
          avatar={<ChecklistRtlIcon />}
          sx={{ bgcolor: 'grey.300' }}
        />
      </Card>
    );
  }

  const multipleAssignments = assignments.length > 1;

  return (
    <Card variant="outlined">
      <CardHeader
        title={<Stack direction="row" spacing={1} alignItems="center">
          <ChecklistRtlIcon />
          <Typography variant="h6" component="span" sx={{ ml: 1 }}>
            Your centre{multipleAssignments ? 's' : ''} {SeasonState.isBefore(season.state, SeasonState.ROUND_1_ADVANCED_RESULTS_VERIFICATION) && ' to mark'}
          </Typography>
      </Stack>}
        subheader={markingAssignmentSubheader(season.state, assignments)}
        sx={{ bgcolor: 'primary.main', color: 'primary.contrastText'  }}
        subheaderTypographyProps={{ sx: { color: 'primary.contrastText' } }}
      />
      <CardContent>
        <Grid container spacing={1}>
          {assignments.map(a => (
            <Grid item xs={12} sm={6} md={4} key={a.centre.id}>
              <Box sx={{ height: '100%' }}>
                <AssignedCentre markingAssignment={a} allowNavigation={season.state === SeasonState.ROUND_1_ADVANCED_MARKING || Account.hasRole(account, AccountRole.ADMIN)} />
              </Box>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
}

function markingAssignmentSubheader(seasonState: SeasonState, assignments: MarkingAssignment[]) {
  if (seasonState === SeasonState.ROUND_1_ADVANCED_MARKING) {
    return assignments.length == 1
      ? `You've been assigned ${assignments[0].centre.name}. Click on it to start marking.`
      : "You've been assigned these centres. Click on them to start marking.";
  }

  if (SeasonState.isBefore(seasonState, SeasonState.ROUND_1_ADVANCED_MARKING)) {
    return assignments.length == 1
      ? `You've been assigned ${assignments[0].centre.name}. You'll be able to start marking when the marking period begins.`
      : "You've been assigned these centres. You'll be able to start marking when the marking period begins.";
  }

  return assignments.length == 1
    ? `You were assigned ${assignments[0].centre.name}. `
    : "You were assigned these centres.";
}
