import Student, { EstimatedStudents, StudentDetails } from '../domain/Student';
import Centre from '../domain/Centre';
import { UUID } from '../domain/UUID';
import axios from 'axios';
import Season, { Exam } from "../domain/Season";
import Gender from '../domain/Gender';

interface GetStudentsRequest {
  centreId: UUID<Centre>,
  seasonId?: UUID<Season>
  examId?: UUID<Exam>
}

interface EstimatedStudentsContext {
  examId: UUID<Exam>,
  centreId: UUID<Centre>
}

interface GetStudentsResponse {
  students: Student[];
}

interface UpdateStudentRequest {
  name: string;
  gender: Gender;
  year: string;
}

export function getStudents(request: GetStudentsRequest): Promise<Student[]> {
  return axios.get<GetStudentsResponse>('/students', {
    params: {
      centreId: request.centreId,
      seasonId: request.seasonId,
      examId: request.examId
    }
  })
    .then(({ data }) => data.students);
}

export function addStudent(studentDetails: StudentDetails): Promise<Student> {
  return axios.post('/students', studentDetails).then(({ data }) => data);
}

export function removeStudent(id: UUID<Student>) {
  return axios.delete(`/students/${id}`).then(({ data }) => data);
}

export function getEstimatedStudents(context: EstimatedStudentsContext): Promise<EstimatedStudents> {
  return axios.get<EstimatedStudents>(`/students/estimate/${context.centreId}/${context.examId}`).then(({ data }) => data);
}

export function putEstimatedStudents(context: EstimatedStudentsContext, estimate: EstimatedStudents): Promise<EstimatedStudents> {
  return axios.put<EstimatedStudents>(`/students/estimate/${context.centreId}/${context.examId}`, estimate).then(({ data }) => data);
}

export function updateStudent(id: UUID<Student>, request: UpdateStudentRequest): Promise<Student> {
  return axios.put<Student>(`/students/${id}`, request).then(({ data }) => data);
}

