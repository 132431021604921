import * as React from "react";
import { useEffect, useState } from "react";
import Centre from "../../../domain/Centre";
import { UUID } from "../../../domain/UUID";
import { Card, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import Student from "../../../domain/Student";
import { getStudents } from "../../../gateway/students";
import CentreStudentsTableDataGrid, { StudentTableEditMode } from "./CentreStudentsTableDataGrid";
import Season from "../../../domain/Season";
import { downloadAllCentreCertificates, getResults } from "../../../gateway/results";
import StudentExamResult from "../../../domain/StudentExamResult";
import SeasonState from "../../../domain/SeasonState";
import DownloadIcon from "@mui/icons-material/Download";
import LoadingButton from '@mui/lab/LoadingButton';
import CentreResultsDownloadButtons from "./CentreResultsDownloadButtons";
import ExamLevel from "../../../domain/ExamLevel";

interface Props {
  readonly season: Season,
  readonly centreId: UUID<Centre>
}

export default function StudentResultsSection({ season, centreId }: Props) {
  const [students, setStudents] = useState<Student[] | undefined>(undefined);
  const [results, setResults] = useState<{ [key: UUID<Student>]: StudentExamResult } | undefined>(undefined);
  const [downloadInProgress, setDownloadInProgress] = useState(false);

  const loadStudents = () => {
    getStudents({ centreId, seasonId: season.id }).then(setStudents);
  };

  useEffect(() => {
    loadStudents();
  }, [season.id, centreId]);

  useEffect(() => {
    if (SeasonState.isOrIsAfter(season.state, SeasonState.ROUND_1_ADVANCED_RESULTS_PUBLICATION)) {
      getResults({ centreId }).then(results => {
        // Filter results based on season state
        const filteredResults: { [key: UUID<Student>]: StudentExamResult } = {};
        
        Object.entries(results).forEach(([studentId, result]) => {
          // Find the exam level by looking up the examId in season.exams
          const examLevel = Object.entries(season.exams)
            .find(([_, exam]) => exam.examId === result.examId)?.[0] as ExamLevel | undefined;
          
          if (SeasonState.isOrIsAfter(season.state, SeasonState.ROUND_1_RESULTS_PUBLICATION)) {
            // Include all results
            filteredResults[studentId] = result;
          } else {
            // Only include Advanced results
            if (examLevel === ExamLevel.ADVANCED) {
              filteredResults[studentId] = result;
            }
          }
        });
        
        setResults(filteredResults);
      });
    }
  }, [season.id, centreId, season.state]);

  const handleStudentUpdated = (updatedStudent: Student) => {
    // Optimistically update the local state
    if (students) {
      setStudents(students.map(student => 
        student.id === updatedStudent.id ? updatedStudent : student
      ));
    }
    
    // Also refresh the data from the server to ensure consistency
    loadStudents();
  };

  if (students === undefined) {
    return <Typography>Loading</Typography>;
  }

  const noStudents = students.length === 0;
  let subheader = undefined;
  if (noStudents) {
    subheader = "You didn't register any students this year.";
    if (SeasonState.isBefore(season.state, SeasonState.ROUND_1_ADVANCED_MARKING)) {
      subheader += " If this was an oversight, you can still upload Advanced exam scripts.";
    } else if (SeasonState.isBefore(season.state, SeasonState.COMPLETE)) {
      subheader += " If this was an oversight, you can still input non-Advanced points.";
    }
  }

  let multipleStudentsHaveAwards = false;
  if (results !== undefined) {
    const studentsWithAwards = Object.values(results).filter((result) => result.award !== null);
    multipleStudentsHaveAwards = studentsWithAwards.length > 1;
  }

  const handleDownloadAllCertificates = () => {
    setDownloadInProgress(true);
    downloadAllCentreCertificates(season.id, centreId, `UKLO_${season.year}_certificates`)
      .finally(() => setDownloadInProgress(false));
  };

  return (
    <Card variant="outlined">
      <CardHeader
        title={`Students for ${season.year}`}
        sx={noStudents ? {} : { pb: 0 }}
        subheader={subheader}
        action={multipleStudentsHaveAwards && (
          <LoadingButton
            loading={downloadInProgress}
            variant="contained"
            onClick={handleDownloadAllCertificates}
            startIcon={<DownloadIcon />}
            sx={{ mr: 1 }}
          >
            Download all certificates
          </LoadingButton>
        )}
      />
      {!noStudents && (
        <CardContent>
          <Stack direction="column" spacing={1}>
            <CentreStudentsTableDataGrid 
              season={season} 
              rows={students} 
              editMode={StudentTableEditMode.DETAILS_EDITABLE} 
              results={results}
              onStudentUpdated={handleStudentUpdated}
          />
          {results && Object.keys(results).length > 0 && (
            <CentreResultsDownloadButtons
              centreId={centreId}
              exams={Object.values(season.exams)}
              results={results}
              season={season}
              />
            )}
          </Stack>
        </CardContent>
      )}
    </Card>
  );
}
