import { DataGrid, GridActionsCellItem, GridColumns } from '@mui/x-data-grid';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import Student from '../../../domain/Student';
import Gender from '../../../domain/Gender';
import { UUID } from '../../../domain/UUID';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip
} from '@mui/material';
import Season, { Exam } from '../../../domain/Season';
import StudentExamResult from '../../../domain/StudentExamResult';
import Award from '../../../domain/Award';
import { downloadStudentCertificate } from "../../../gateway/results";
import sanitizeFileName from "../../common/sanitizeFileName";
import { useState } from 'react';
import { updateStudent } from '../../../gateway/students';

export enum StudentTableEditMode {
  NOT_EDITABLE = 'NOT_EDITABLE',
  DETAILS_EDITABLE = 'DETAILS_EDITABLE',
  REGISTRATION_EDITABLE = 'REGISTRATION_EDITABLE',
}

interface Props {
  readonly season: Season;
  readonly rows: Student[];
  readonly results?: { [key: UUID<Student>]: StudentExamResult } | undefined;
  readonly displayRegistered?: boolean;
  readonly editMode?: StudentTableEditMode;
  readonly onDeleteStudent?: (studentId: UUID<Student>) => void;
  readonly onStudentUpdated?: (student: Student) => void;
}

interface EditStudentDialogProps {
  student: Student;
  open: boolean;
  onClose: () => void;
  onSave: (student: Student) => void;
}

function EditStudentDialog({ student, open, onClose, onSave }: EditStudentDialogProps) {
  const [name, setName] = useState(student.name);
  const [gender, setGender] = useState(student.gender);
  const [year, setYear] = useState(student.year);
  const [saving, setSaving] = useState(false);
  const [nameError, setNameError] = useState<string>('');

  const validateForm = (): boolean => {
    const trimmedName = name.trim();
    if (!trimmedName) {
      setNameError('Name is required');
      return false;
    }
    setNameError('');
    return true;
  };

  const handleSave = async () => {
    if (!validateForm()) {
      return;
    }

    setSaving(true);
    try {
      const updatedStudent = await updateStudent(student.id, {
        name: name.trim(),
        gender,
        year: year.trim()
      });
      onSave(updatedStudent);
      onClose();
    } catch (error) {
      console.error('Failed to update student:', error);
      // You might want to show an error message to the user here
    } finally {
      setSaving(false);
    }
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    if (nameError) {
      validateForm();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Edit Student Details</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
          <TextField
            label="Name"
            value={name}
            onChange={handleNameChange}
            onBlur={() => validateForm()}
            error={!!nameError}
            helperText={nameError}
            fullWidth
            required
          />
          <FormControl fullWidth required>
            <InputLabel>Gender</InputLabel>
            <Select
              value={gender}
              label="Gender"
              onChange={(e) => setGender(e.target.value as Gender)}
            >
              {Gender.values().map((g) => (
                <MenuItem key={g} value={g}>
                  {Gender.pretty(g)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Year"
            value={year}
            onChange={(e) => setYear(e.target.value)}
            fullWidth
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          disabled={saving}
          sx={{ mr: 1 }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          disabled={saving}
          variant="contained"
          color="primary"
        >
          {saving ? 'Saving...' : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default function CentreStudentsTableDataGrid({
                                                      season,
                                                      rows,
                                                      results,
                                                      displayRegistered,
                                                      editMode = StudentTableEditMode.NOT_EDITABLE,
                                                      onDeleteStudent,
                                                      onStudentUpdated,
                                                    }: Props) {
  const [editingStudent, setEditingStudent] = useState<Student | null>(null);

  const handleStudentUpdated = (updatedStudent: Student) => {
    onStudentUpdated?.(updatedStudent);
    setEditingStudent(null);
  };

  const columns: GridColumns<Student> = [
    { headerName: 'Name', flex: 5, field: 'name', valueGetter: (params) => params.row.name },
    { headerName: 'Gender', flex: 2, field: 'gender', valueGetter: (params) => Gender.pretty(params.row.gender) },
    {
      headerName: 'Year',
      flex: 2,
      field: 'year',
      valueGetter: (params) => (params.row.year === '' ? 'Unknown' : params.row.year),
    },
    {
      headerName: 'Exam',
      flex: 3,
      field: 'examId',
      valueGetter: (params) =>
        Exam.name(season, Object.values(season.exams).find((e) => e.examId === params.row.examId)),
    },
  ];

  if (results !== undefined) {
    columns.push(
      {
        headerName: 'Marks',
        flex: 2,
        field: 'marks',
        align: 'right',
        headerAlign: 'right',
        valueGetter: (params) => {
          const studentResult = results[params.row.id];
          if (studentResult === undefined) {
            return 0;
          }
          return parseFloat(studentResult.totalMarks) || 0;
        },
        valueFormatter: ({ value }) => (value > 0 ? value.toFixed(2) : '-'),
      },
      {
        headerName: 'Percentage',
        flex: 2,
        field: 'percentage',
        align: 'right',
        headerAlign: 'right',
        valueGetter: (params) => {
          const studentResult = results[params.row.id];
          if (studentResult === undefined) {
            return 0;
          }
          return parseFloat(studentResult.percentage) || 0;
        },
        valueFormatter: ({ value }) => (value > 0 ? `${value.toFixed(2)}%` : '-'),
      },
      {
        headerName: 'Award',
        flex: 2,
        field: 'award',
        valueGetter: (params) => {
          const studentResult = results[params.row.id];
          if (studentResult === undefined) {
            return null;
          }
          return studentResult.award;
        },
        valueFormatter: ({ value }) => Award.toHumanString(value),
        sortComparator: (v1, v2) => {
          if (v1 === null && v2 === null) {
            return 0;
          }
          if (v1 === null) {
            return -1;
          }
          if (v2 === null) {
            return 1;
          }
          return Award.compare(v1, v2);
        },
      }
    );

    const anyStudentHasAward = rows.some((student) => {
      const studentResult = results[student.id];
      return studentResult && studentResult.award;
    });

    if (anyStudentHasAward) {
      columns.push({
        headerName: 'Certificate',
        field: 'certificate',
        width: 100,
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params) => {
          const studentResult = results[params.row.id];
          if (studentResult && studentResult.award) {
            const requestedFileName = sanitizeFileName(params.row.name);
            return (
              <IconButton
                onClick={() => downloadStudentCertificate(params.row.id, requestedFileName)}
                aria-label="Download Certificate"
              >
                <Tooltip title="Download Certificate">
                  <DownloadIcon />
                </Tooltip>
              </IconButton>
            );
          } else {
            return "-";
          }
        },
      });
    }
  }

  if (displayRegistered) {
    columns.push({ headerName: 'Registered', sortable: false, field: 'registered', valueGetter: () => 'Yes' });
  }

  if (rows.some((student) => student.createdByMarker)) {
    columns.splice(1, 0, {
      headerName: 'Added in marking',
      flex: 3,
      field: 'createdByMarker',
      headerAlign: 'left',
      align: 'left',
      valueGetter: ({ row }) => row.createdByMarker,
      renderCell: ({ row }) => (row.createdByMarker ? 'Yes' : 'No'),
    });
  }

  if (editMode !== StudentTableEditMode.NOT_EDITABLE) {
    columns.push({
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: editMode === StudentTableEditMode.DETAILS_EDITABLE ? 100 : 100,
      cellClassName: 'actions',
      getActions: ({ row }) => {
        if (editMode === StudentTableEditMode.REGISTRATION_EDITABLE) {
          return [
            // @ts-ignore
            <GridActionsCellItem
              icon={
                <Tooltip title="Unregister">
                  <CloseIcon />
                </Tooltip>
              }
              label="Unregister"
              onClick={() => onDeleteStudent?.(row.id)}
              color="error"
              showInMenu={false}
            />,
          ];
        } else if (editMode === StudentTableEditMode.DETAILS_EDITABLE) {
          return [
            // @ts-ignore
            <GridActionsCellItem
              icon={
                <Tooltip title="Edit">
                  <EditIcon />
                </Tooltip>
              }
              label="Edit"
              onClick={() => setEditingStudent(row)}
              showInMenu={false}
            />,
          ];
        }
        return [];
      },
    });
  }

  return (
    <>
      <DataGrid
        rows={rows}
        columns={columns}
        getRowId={(student) => student.id}
        density="compact"
        autoHeight
        pageSize={Math.min(rows.length, 100)}
        disableColumnSelector
        disableSelectionOnClick
        initialState={{
          sorting: {
            sortModel: [{ field: 'name', sort: 'asc' }],
          },
        }}
      />
      {editingStudent && (
        <EditStudentDialog
          student={editingStudent}
          open={true}
          onClose={() => setEditingStudent(null)}
          onSave={handleStudentUpdated}
        />
      )}
    </>
  );
}
